import React from 'react';

import GlobalStyle, { variables } from '../styles/GlobalStyles'

import StyledTableOfContents from '../styles/case-study/TableOfContents'
import CaseStudyStyles from '../styles/case-study/CaseStudy'
import CaseStudyHero from '../styles/case-study/HeroBanner'
import { SketchSlider, ColorSlider, A11ySlider } from '../components/slider/safely';
import RelatedCarousel from '../components/caseStudyComponents/relatedCarousel';
import { Grid } from '../styles/case-study/Grid';
import { Card, BoxCard } from '../styles/case-study/Card';
import CTAButton from '../styles/case-study/CTAButton';
import EmphasisBlock from '../styles/case-study/EmphasisBlock';
import Persona from '../styles/case-study/Persona';
import { Summary, SummaryItem } from '../styles/case-study/Summary';

import SEO from '../components/seo'
import Nav from '../components/nav'
import Wrapper from '../styles/Wrapper'
import { Section } from '../styles/Section';
import Footer from '../components/footer';


const IndexPage = () => {
  return (
    <>
      <GlobalStyle />
      {/* eslint-disable-next-line */}
      <SEO title="Jonathan Hoy - Safely" />
      <header>
        <Nav casestudy={true} />
        <StyledTableOfContents brandColor900={`${variables.safelyBrandColor900}`} brandColor100={`${variables.safelyBrandColor100}`} className='sidebar'>
          <Wrapper>
            <ol>
              <li><a href="#overview"><span className='num'>1. </span>Overview</a></li>
              <li><a href="#problem"><span className='num'>2. </span>Problem</a></li>
              <li><a href="#solution"><span className='num'>3. </span>Solution</a></li>
              <li><a href="#research"><span className='num'>4. </span>Research</a></li>
              <li><a href="#ideation"><span className='num'>5. </span>Ideation</a></li>
              <li><a href="#prototype"><span className='num'>6. </span>Prototype</a></li>
              <li><a href="#branding"><span className='num'>7. </span>Branding</a></li>
              <li><a href="#support"><span className='num'>8. </span>Support</a></li>
              <li><a href="#outcomes"><span className='num'>9. </span>Outcomes</a></li>
            </ol>
          </Wrapper>
        </StyledTableOfContents>
      </header>
      <main className='case-study'>
        <CaseStudyHero src="/case-study/safely/hero-banner.png" alt="Banner with the text, Travel with Safely. Don't let allergy anxiety get in the way of your travels."></CaseStudyHero>
        <Section>
          <Wrapper>
            <CaseStudyStyles brandColor900={`${variables.safelyBrandColor900}`} brandColor100={`${variables.safelyBrandColor100}`} heading={`${variables.safelyBrandColor900}`}>
              <div className='text-container' id="overview">
                <h2><span className='number'>1</span>Overview</h2>
                <Summary columns="3">
                  <SummaryItem rows="1 / 2" columns="1 / 3">
                    <p className='heading'>The Project</p>
                    <p className='description'>Academic project for BrainStation's UX Design Diploma program. The problem space is concerning travelers with severe food allergies. These individuals face fear and anxiety when traveling abroad due to uncertainties regarding the local cuisine as it relates to their allergies.</p>
                    <p className='description'>This solution allows travelers with food allergies to travel abroad, experience new cultures, and navigate around local cuisines. This is an individual case study spanning the design process from problem discovery, definition, developing, and design iteration.</p>
                  </SummaryItem>
                  <SummaryItem rows="2 / 3" columns="1 / 2">
                    <p className='heading'>My Roles</p>
                    <ul className='list no-bullet small-mt'>
                      <li><p>UX Researcher</p></li>
                      <li><p>UX/UI Designer</p></li>
                    </ul>
                  </SummaryItem>
                  <SummaryItem rows="2 / 3" columns="2 / 3">
                    <p className='heading'>Timeline</p>
                    <p className='description'>3 months</p>
                  </SummaryItem>
                  <SummaryItem rows="3 / 4" columns="1 / 2">
                    <p className='heading'>Tools</p>
                    <ul className='list no-bullet small-mt'>
                      <li><p>Figma</p></li>
                      <li><p>Figjam</p></li>
                      <li><p>InVision</p></li>
                    </ul>
                  </SummaryItem>
                  <SummaryItem rows="3 / 4" columns="2 / 3">
                    <p className='heading'>Outcomes</p>
                    <ul className='list no-bullet small-mt'>
                      <li><p><span className='bold'>96%</span> task completion rate in user testing</p></li>
                      <li><p><span className='bold'>100%</span> of testers would use this solution</p></li>
                    </ul>
                  </SummaryItem>
                  <SummaryItem rows="1 / 4" columns="3 / 4" align="center">
                    <img src="/case-study/safely/prototype-preview.png" alt="" className='width-50'></img>
                    <CTAButton primary={`${variables.safelyBrandColor900}`} secondary={`${variables.white}`}>
                      <a href="https://www.figma.com/proto/L7R8SfPRUCkOkLZ2yPEbXn/Safely?page-id=3%3A33752&type=design&node-id=1408-8279&viewport=832%2C903%2C0.28&t=4omuFoFaUM7aHdTn-1&scaling=scale-down&starting-point-node-id=1408%3A8279&mode=design" target="_blank" rel="noreferrer">View interactive prototype <span className='arrow'>→</span></a>
                    </CTAButton>
                  </SummaryItem>
                </Summary>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Design Methodology</h3>
                <img src="/case-study/safely/double-diamond.png" alt="" className='width-75'></img>
                <p>The methodolgy used to complete this project was the <span className='bold'>double diamond design process</span>. This process helped to guide me in discovering the true problem and defining, designing, and iterating a solution for that problem.</p>
              </div>


              <div className='text-container' id="problem">
                <h2><span className='number'>2</span>The Problem</h2>
                <p>People with food allergies, who represent up to 10.8% of the global population, have the problem that they <span className='bold'>experience fear and anxiety when traveling abroad</span> because they may be put in a life-or-death situation when eating the local cuisine. Uncertainties about the ingredients in dishes and language barriers with food handlers contribute to these travelers’ fear and anxiety.</p>
                <p>According to a survey posted to the <a href="https://www.medsciencegroup.us/Allergy/Allergy-1-109.php" target="_blank" rel="noreferrer" className='inline'>Global Journal of Allergy <span className='arrow'>→</span></a>:</p>
                <Grid columns="3">
                  <BoxCard>
                    <p className='headline'>8%</p>
                    <p className='description'>of people with food allergies <span className='bold'>do not travel at all</span> due to their food allergies.</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='headline'>46%</p>
                    <p className='description'>of people with food allergies <span className='bold'>experience fear</span> when traveling abroad.</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='headline'>49%</p>
                    <p className='description'>of people with food allergies <span className='bold'>experience anxiety</span> when traveling abroad.</p>
                  </BoxCard>
                </Grid>
              </div>

              <div className='text-container' id="solution">
                <span className='section-anchor' aria-disabled="true"></span>
                <h2><span className='number'>3</span>The Solution</h2>
                <h3 className='subheading'>Helping travelers with food allergies to find safe restaurants and communicate their allergies</h3>
                <div className='iframe-container'>
                  <iframe src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FL7R8SfPRUCkOkLZ2yPEbXn%2FSafely%3Fpage-id%3D3%253A33752%26type%3Ddesign%26node-id%3D1408-8279%26viewport%3D832%252C903%252C0.28%26t%3D4omuFoFaUM7aHdTn-1%26scaling%3Dscale-down%26starting-point-node-id%3D1408%253A8279%26mode%3Ddesign" allowfullscreen title="High-fidelity Prototype"></iframe>
                </div>
                <p>This solution is the result of multiple rounds of usability testing and iteration. The solution aims to help travelers with food allergies find restaurants that are safe to eat at, provide directions to those restaurants, and then translate their allergies to the waitstaff.</p>
              </div>

              <div className='text-container' id="research">
              <h2><span className='number'>4</span>Primary Research</h2>
                <h3 className='subheading'>Guiding my Research</h3>
                <p>I assumed that the <span className='bold'>local language barrier</span> was the greatest contributor to the fear and anxiety of travelers and so I crafted the following hypothesis to guide my research:</p>
                <EmphasisBlock brandColor900={`${variables.safelyBrandColor900}`} brandColor100={`${variables.safelyBrandColor100}`}>
                  <p>I believe language barriers create skepticism or doubts in people with severe food allergies that prevent them from trusting the local cuisine while abroad.</p>
                  <p>I will know this to be true when the majority of my interview respondents acknowledge that language barriers have been a factor that have caused hesitation in choosing a travel destination.</p>
                </EmphasisBlock>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Forming Assumptions</h3>
                <Grid columns="2">
                  <BoxCard>
                    <p className='headline'>1</p>
                    <p>People have a need to know the food ingredients of dishes when traveling abroad to ensure they are eating safe food.</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='headline'>2</p>
                    <p>These needs can be solved with trip preparation and education, and simple ways to communicate abroad.</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='headline'>3</p>
                    <p>My initial users will be people who have food allergies who have expressed an interest to travel but have fears or anxieties to travel.</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='headline'>4</p>
                    <p>The #1 value a user wants to get from my solution is being able to navigate the local cuisine safely while abroad.</p>
                  </BoxCard>
                </Grid>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>User Research</h3>
                <Grid columns="2">
                  <div>
                    <p>For primary research, my research method was <span className='bold'>user interviews</span>. I was able to interview three participants all of whom met my participant criteria:</p>
                    <ol>
                      <li><p>Participants have a food allergy.</p></li>
                      <li><p>Participants have had an allergic reaction in the past.</p></li>
                      <li><p>Participants expressed a desire to travel.</p></li>
                    </ol>
                  </div>
                  <BoxCard className='warning-block'>
                    <p className='headline'>⚠️</p>
                    <p className='description'>Due to time constraints, I was limited in the demographic of respondents available to me. Although each participant met the above criteria, the three participants I interviewed were similar in demographics and even allergies. I would have liked the opportunity to interview more diverse respondents to allow for a wider variety of experiences and insights.</p>
                  </BoxCard>
                </Grid>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>What Interviewees are Saying</h3>
                <Grid columns="3">
                  <BoxCard>
                    <p className='description'>"I don't want to travel somewhere with my friends and feel like I'm weighing them down - I don't want to be a burden on the trip"</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='description'>"If I'm traveling with friends, I don't want to hold back the group in choosing restaurants"</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='description'>"I still want to experience local culture, I would try to find restaurants that can communicate well"</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='description'>"I would prepare by trying to learn a bit of the language to at least let them know my allergies"</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='description'>"I would prepare by looking up translations of food allergies"</p>
                  </BoxCard>
                </Grid>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Key Insights Gained from User Interviews</h3>
                <Grid columns="2">
                  <Card>
                    <p className='heading'>Allergies in social settings</p>
                    <p className='description'>People with food allergies are hesitant and cautious when faced with social situations that could potentially involve their allergies. Because of the severity of their food allergies, respondents take great care in researching new dishes or restaurants and want to know what ingredients go into a dish.</p>
                  </Card>
                  <Card>
                    <p className='heading'>Trip experience</p>
                    <p className='description'>People with food allergies don’t want to be a burden to those they are traveling with. They hesitate about eating the local cuisine due to communication issues.</p>
                  </Card>
                  <Card>
                    <p className='heading'>Trip preparation & planning</p>
                    <p className='description'>While understanding limitations due to their allergies, people with food allergies have expressed a desire to travel to foreign countries. Confidence can be created with preparation - epipens, medicine, efforts to communicate over a language barrier, and more stringent restaurant selection.</p>
                  </Card>
                </Grid>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Creating a Persona to Summarize Research</h3>
                <Persona>
                  <div className='avatar'>
                    <img src="/case-study/safely/persona-jacob.png" alt=""></img>
                    <p className='name'>Jacob</p>
                  </div>
                  <div className='goals'>
                    <p className='heading'>Goals</p>
                    <ul className='list'>
                      <li><p><span className='bold'>Confidence</span> that the food he eats will not contain anything he is allergic to</p></li>
                      <li><p><span className='bold'>Find restaurants</span> that he can trust to eat at</p></li>
                    </ul>
                  </div>
                  <div className='painpoints'>
                    <p className='heading'>Pain Points</p>
                    <ul className='list'>
                      <li><p>Feels <span className='bold'>anxious</span> when he doesn’t know what ingredients are in a dish</p></li>
                      <li><p><span className='bold'>Hesitates</span> to ask about his allergies because of language barriers</p></li>
                    </ul>
                  </div>
                  <p className='bio'>Jacob is interested in traveling to Thailand, but because of his food allergies he is hesitant to travel. He doesn’t want to be anxious at every meal or be an inconvenience to his friends.</p>
                </Persona>
              </div>


              <div className='text-container'>
                <h3 className='subheading'>Mapping the Current Experience</h3>
                <p>I mapped out the current experience of my persona to have a better understanding of potential opportunities for design intervention.</p>
                <div className='image-container'>
                  <img src="/case-study/safely/experience-mapping.png" alt=""></img> 
                </div>               
              </div>

              <div className='text-container'>
                <h3 className='subheading'>How Might We Solve for this Problem?</h3>
                <EmphasisBlock brandColor900={`${variables.safelyBrandColor900}`} brandColor100={`${variables.safelyBrandColor100}`}>
                  <p>How might we help people with food allergies who are traveling so that they won’t eat any food that contains their allergies?</p>
                </EmphasisBlock>
              </div>

              <div className='text-container' id="ideation">
                <h2><span className='number'>5</span>Ideation</h2>
                <h3 className='subheading'>Brainstorming Potential Solutions</h3>
                <p>With key opportunities identified in my experience map, I authored user stories to outline the features and functionality of a potential solution. I categorized my user stories into four epics based on:</p>
                <ol>
                  <li><p>Dining abroad</p></li>
                  <li><p>Epipens</p></li>
                  <li><p>Social features</p></li>
                  <li><p>Travel preparation</p></li>
                </ol>
                <p>While each epic would contribute to building a feature-rich solution, I decided the <span className='bold'>dining abroad</span> epic is the most robust and would best serve to create an MVP solution.</p>
                <h4 className='subheading'>Chosen Epic: Dining Abroad</h4>
                <Grid columns="3">
                  <BoxCard>
                    <p className='heading'>User Story</p>
                    <p className='description'><span className='bold'>As a</span> traveler</p>
                    <p className='description'><span className='bold'>I want to</span> find restaurants near me</p>
                    <p className='description'><span className='bold'>So that </span> I have a place to eat that’s easy to get to</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='heading'>User Story</p>
                    <p className='description'><span className='bold'>As a</span> traveler</p>
                    <p className='description'><span className='bold'>I want to</span> translate my food allergies</p>
                    <p className='description'><span className='bold'>So that </span> I can easily tell waitstaff about my allergies</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='heading'>User Story</p>
                    <p className='description'><span className='bold'>As a</span> traveler</p>
                    <p className='description'><span className='bold'>I want to</span> view if restaurants are trusted</p>
                    <p className='description'><span className='bold'>So that </span> I know instantly that it’s safe to eat there</p>
                  </BoxCard>
                </Grid>
                <p>I believe actively helping travelers while they are abroad to navigate around their food allergies is the goal of any proposed solution for this problem space and would provide the most value.</p>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Task Flow</h3>
                <p>My proposed solution would allow users to find restaurants, navigate to them, and translate their allergies to waitstaff.</p>
                <div className='image-container'>
                  <img src="/case-study/safely/task-flow.png" alt=""></img>
                </div>
              </div>


              <div className='text-container'>
                <h3 className='subheading'>Sketching into Mid-fidelity Wireframes</h3>
                <p>I began to sketch what screens would look like and the information they would be required to convey. These wireframes were used for the first round of user testing to gain feedback before committing to the design for a high-fidelity prototype.</p>
                <SketchSlider />
                <img src="/case-study/safely/mid-fi-wireframes.png" alt=""></img>
                <div className='image-container'>
                </div>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>My Design Decisions</h3>
                <Grid columns="2" align="center">
                  <div className='asset'>
                    <img src="/case-study/safely/design-decision-1.png" alt=""></img>
                  </div>
                  <Card>
                    <p className='heading'>Emphasis on search and discoverability</p>
                    <p className='description'>I wanted the search feature to be highly visible and accessible. It is on the dashboard to emphasize how important searching for restaurants is in my app.</p>
                  </Card>
                </Grid>
              </div>

              <div className='text-container'>
                <Grid columns="2" align="center">
                  <Card>
                    <p className='heading'>Visibility of allergens and prioritization of safe options</p>
                    <p className='description'>A user can easily see what allergens may be present at a restaurant. Restaurant search results are sorted by allergen-free options, and then other restaurants where some dishes will include a user’s allergens.</p>
                  </Card>
                  <div className='asset'>
                    <img src="/case-study/safely/design-decision-2.png" alt=""></img>
                  </div>
                </Grid>
              </div>

              <div className='text-container'>
                <Grid columns="2" align="center">
                  <div className='asset'>
                    <img src="/case-study/safely/design-decision-3.png" alt=""></img>
                  </div>
                  <Card>
                    <p className='heading'>Translating allergies to communicate effectively</p>
                    <p className='description'>When a user wants to translate their allergies, they can do so by either typing in what they would like to translate or take advantage of pre-populated Quick Phrases. Quick Phrases are also found on the home screen for convenient access for the user.</p>
                  </Card>
                </Grid>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Let’s Test my Solution against Real People</h3>
                <p>With my initial design complete, I performed usability testing with five testers.</p>
                <Grid columns="2">
                  <BoxCard>
                    <p className='headline'>👍</p>
                    <p className='heading'>Highlights</p>
                    <ul className='list'>
                      <li><p>Testers found the most value in the Translation page, specifically the manual translate and Quick Phrases</p></li>
                      <li><p>Testers appreciated the distinction between allergen-free options and all options, for both restaurants and their menus</p></li>
                    </ul>
                  </BoxCard>
                  <BoxCard>
                    <p className='headline'>🛠️</p>
                    <p className='heading'>Room for Improvement</p>
                    <ul className='list'>
                      <li><p>The 'Begin Translation' CTA was consistently confusing</p></li>
                      <li><p>The Directions page felt incomplete</p></li>
                      <li><p>Testers could not visualize the expected outcome of interacting with Quick Cards</p></li>
                    </ul>
                  </BoxCard>
                </Grid>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>V2 Iteration, Let’s Try Again</h3>
                <img src="/case-study/safely/v1-v2-change-1.png" alt=""></img>
                <p>The most consistent feedback received during round 1 of user testing was that this 'Begin Translation' CTA was confusing. The context could not be interpreted from the other elements on the page. I updated this CTA to become a location-based modal that would appear when the user has arrived at their destination. I also updated the copy to more clearly communicate to the user the intent of the CTA.</p>
                <p>After some additional changes that were prioritized with a design prioritization matrix, I was ready for a second round of usability testing to validate my new changes.</p>
                <div className='image-container'>
                  <img src="/case-study/safely/prioritization-matrix-1.png" alt=""></img>
                </div>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Test, Test, Test Again</h3>
                <p>I was <span className='bold'>able to validate</span> that the changes I made for V2 were successful because testers in this round did not have any feedback for the major areas of concern from round 1. That is not to say my design was perfect as testers still provided feedback for other things.</p>
                <Grid columns="2">
                  <BoxCard>
                    <p className='headline'>👍</p>
                    <p className='heading'>Highlights</p>
                    <ul className='list'>
                      <li><p>The Begin Translation CTA was iterated upon and no negative feedback was received in round 2 indicating my change has the desired effect</p></li>
                      <li><p>Copy updates help clarify actions and their context</p></li>
                      <li><p>'Verified by app' badge on restaurants is great feedback that would help boost confidence of travelers</p></li>
                    </ul>
                  </BoxCard>
                  <BoxCard>
                    <p className='headline'>🛠️</p>
                    <p className='heading'>Room for Improvement</p>
                    <ul className='list'>
                      <li><p>Testers have expressed over two rounds to have distance from user to restaurant to provide context</p></li>
                      <li><p>Ratings versus reviews and the implied meaning of the associated iconography</p></li>
                      <li><p>Testers could not visualize the expected outcome of interacting with Quick Cards</p></li>
                    </ul>
                  </BoxCard>
                </Grid>
              </div>

              <div className='text-container' id="prototype">
                <h2><span className='number'>6</span>Prototype</h2>
                <h3 className='subheading'>High-fidelity Mock-up</h3>
                <p>After two rounds of user testing, I was confident that my design solution was closely aligned to solving the initial problem I set out to solve for and so I was ready to commit to the design by implenting branding, personality, colour, imagery, and animations.</p>
                <CTAButton primary={`${variables.safelyBrandColor900}`} secondary={`${variables.white}`}>
                  <a href="https://www.figma.com/proto/L7R8SfPRUCkOkLZ2yPEbXn/Safely?page-id=3%3A33752&type=design&node-id=1408-8279&viewport=832%2C903%2C0.28&t=4omuFoFaUM7aHdTn-1&scaling=scale-down&starting-point-node-id=1408%3A8279&mode=design" target="_blank" rel="noreferrer">View interactive prototype <span className='arrow'>→</span></a>
                </CTAButton>
                <img src="/case-study/safely/hifi.png" alt="" className='mt-2'></img>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Features of my Solution</h3>
                <Grid columns="2" align="center">
                  <div className='asset video'>
                    <video controls autoPlay muted loop playsinline>
                      <source 
                        src="https://binsta.dev/api/v1/files/2grKa6eeA1/transform?format=webm&size=lg&quality=md" 
                        type="video/webm" 
                      />
                      <source 
                        src="https://binsta.dev/api/v1/files/2grKa6eeA1/transform?format=mp4&size=lg&quality=md" 
                        type="video/mp4"  
                      />
                    </video>
                  </div>
                  <Card>
                    <p className='heading'>Search for restaurants</p>
                    <p className='description'>Find restaurants near you and see restaurants sorted by their allergens. The app will always provide <span className='bold'>safe</span> options first.</p>
                  </Card>
                </Grid>
              </div>

              <div className='text-container'>
                <Grid columns="2" align="center">
                  <Card>
                    <p className='heading'>View a restaurant's menu</p>
                    <p className='description'>When choosing a restaurant that isn't allergen-free, be reminded of present allergens and see <span className='bold'>safe</span> options to eat.</p>
                  </Card>
                  <div className='asset video'>
                    <video controls autoPlay muted loop playsinline>
                      <source 
                        src="https://binsta.dev/api/v1/files/8GMyrvISuj/transform?format=webm&size=lg&quality=md" 
                        type="video/webm" 
                      />
                      <source 
                        src="https://binsta.dev/api/v1/files/8GMyrvISuj/transform?format=mp4&size=lg&quality=md" 
                        type="video/mp4"  
                      />
                    </video>
                  </div>
                </Grid>
              </div>

              <div className='text-container'>
                <Grid columns="2" align="center">
                  <div className='asset video'>
                    <video controls autoPlay muted loop playsinline>
                      <source 
                        src="https://binsta.dev/api/v1/files/JJxLC8-8kw/transform?format=webm&size=lg&quality=md" 
                        type="video/webm" 
                      />
                      <source 
                        src="https://binsta.dev/api/v1/files/JJxLC8-8kw/transform?format=mp4&size=lg&quality=md" 
                        type="video/mp4"  
                      />
                    </video>
                  </div>
                  <Card>
                    <p className='heading'>Communicate your allergies</p>
                    <p className='description'>Type a phrase to translate it and add it to a list of Quick Phrases that are conveniently saved.</p>
                  </Card>
                </Grid>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>V3 Iteration, Getting Closer</h3>
                <img src="/case-study/safely/v2-v3-change-1.png" alt=""></img>
                <p>A few changes that were made for the high-fidelity mock-up include changing the thumbs up icon to a star icon. The original intention was that other travelers would give a restaurant their approval by tapping the thumbs up. However, during user testing it was revealed that testers felt that this system of rating wasn't as trustworthy or as valuable as a star rating. Testers felt that a star rating (assuming a scale of 0-5) provided a more accurate reflection of the traveler's experience with that restaurant.</p>
                <p>Speaking of trustworthiness, another valuable insight gleaned from the second round of user testing was showing a badge that the app has verified certain restaurants to be safe. Some testers would believe the word of the app much more than the word of a restaurant so to see that the app has verified a restaurant afforded a high level of trust.</p>
                <p>Changes other than the two mentioned above that were deemed to be high impact and low effort were also implemented based on this design prioritization matrix.</p>
                <div className='image-container'>
                  <img src="/case-study/safely/prioritization-matrix-2.png" alt=""></img>
                </div>
              </div>

              <div className='text-container' id="branding">
                <h2><span className='number'>7</span>Branding</h2>
                <h3 className='subheading'>Colors</h3>
                <ColorSlider />
                <h3 className='subheading'>Color Contrast Accessibility</h3>
                <A11ySlider />
              </div>

              <div className='text-container' id="support">
                <h2><span className='number'>8</span>Supporting Materials</h2>
                <h3 className='subheading'>Marketing Site</h3>
                <p>I designed a responsive marketing site communicating the value proposition of my solution by showcasing the key features of my app.</p>
                <div className='image-container borderless'>
                  <img src="/case-study/safely/marketing-site.jpg" alt=""></img>
                </div>
                <Grid columns="2">
                  <BoxCard>
                    <p className='headline'>🖥️</p>
                    <p className='heading'>Desktop Marketing Site</p>
                    <CTAButton align="start" primary={`${variables.safelyBrandColor900}`} secondary={`${variables.white}`}>
                      <a href="https://www.figma.com/proto/L7R8SfPRUCkOkLZ2yPEbXn/Safely?page-id=3%3A38949&node-id=3-45049&viewport=473%2C-250%2C0.24&scaling=min-zoom" target="_blank" rel="noreferrer">View desktop marketing site <span className='arrow'>→</span></a>
                    </CTAButton>
                  </BoxCard>
                  <BoxCard>
                    <p className='headline'>📱</p>
                    <p className='heading'>Mobile Marketing Site</p>
                    <CTAButton align="start" primary={`${variables.safelyBrandColor900}`} secondary={`${variables.white}`}>
                      <a href="https://www.figma.com/proto/L7R8SfPRUCkOkLZ2yPEbXn/Safely?page-id=3%3A38950&node-id=3-47679&viewport=494%2C386%2C0.08&scaling=min-zoom" target="_blank" rel="noreferrer">View mobile marketing site <span className='arrow'>→</span></a>
                    </CTAButton>                  
                  </BoxCard>
                </Grid>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Scaling for new Experiences</h3>
                <img src="/case-study/safely/mockup-watch.png" alt=""></img>
                <p>As proof of concept, I designed an experience for an additional device. The nature of <span className='italic'>Safely</span> as a tool while traveling naturally lends itself to being used on mobile devices, as opposed to desktops or tablets. With that in mind I thought of other screens and devices where a user would likely be using the app.</p>
                <p>Smartwatches came to mind as someone on the go might want to find directions to a restaurant. I created an abbreviated version of my original flow where users could view their saved restaurants and get directions to that restaurant.</p>
                <div className='image-container'>
                  <img src="/case-study/safely/task-flow-watch.png" alt=""></img>
                </div>
              </div>

              <div className='text-container' id="outcomes">
                <h2><span className='number'>9</span>Project Outcomes</h2>
                <h3 className='subheading'>Verified Outcomes</h3>
                <Grid columns="3">
                  <BoxCard>
                    <p className='headline'>96%</p>
                    <p className='heading'>Task completion rate</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='headline'>92%</p>
                    <p className='heading'>Unconditional task completion rate</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='headline'>10/10</p>
                    <p className='heading'>Testers would use this solution on a trip abroad</p>
                  </BoxCard>
                </Grid>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>How else would success be measured?</h3>
                <Grid columns="2">
                  <p>If <span className='italic'>Safely</span> were to be built and launched, these are some KPIs to measure against:</p>
                  <BoxCard>
                    <p className='heading'>Key Performance Indicators</p>
                    <ul className='list'>
                      <li><p>Monthly app downloads</p></li>
                      <li><p>App usage follow-through: the % of users who have downloaded the app and have used it on a trip abroad</p></li>
                      <li><p>Rate of restaurants signing up for service or requesting verification</p></li>
                    </ul>
                  </BoxCard>
                </Grid>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Key Learnings</h3>
                <Grid columns="2">
                  <BoxCard>
                    <p className='headline'>📝</p>
                    <p className='heading'>Preparing a strong user interview script</p>
                    <p className='description'>I believe the quality of the interview script as well as the interviewer's ability to go off-script and probe in certain areas with respondents will lead to greater insights that will affect the entire project going forward.</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='headline'>🔎</p>
                    <p className='heading'>Research the problem before designing a solution</p>
                    <p className='description'>It can be easy to get carried away and design a solution early in the process but without truly understanding users who are affected by this problem space, a solution at this point is not truly solving the problem.</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='headline'>🧑‍💻</p>
                    <p className='heading'>The importance of user testing</p>
                    <p className='description'>Despite following the design process to inform a solution that I believe would best satisfy the needs of my users, user testing always reveals insights and validates or invalidates assumptions I have about my design decisions.</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='headline'>🗑️</p>
                    <p className='heading'>Don't get attached to designs</p>
                    <p className='description'>Features that I envisioned would provide the most value ended up not being features that testers cared much for.</p>
                  </BoxCard>
                </Grid>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Next Steps</h3>
                <p>The solution that has been designed is only one task flow that encompasses one epic of user stories that I have authored. My chosen epic serves to create a MVP but there are more features and functionality that I would like to implement in the future.</p>
                <p>The social aspect is one that I think would add value. Being able to share and read other travelers' experiences who share the same allergies would instill even more confidence in the user that positively affects their decision-making. The user would be able to create a list of the restaurants they visited over the duration of a trip and share that list with other travelers. I believe sharing these experiences helps to create a safer and more confident community of would-be travelers.</p>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Future Thinking</h3>
                <Grid columns="1">
                  <BoxCard>
                    <p className='heading'>What happens when 100 million people use your product?</p>
                    <p className='description'>A large user-base creates a robust database of restaurants that are safe to eat at. The result of more users contributing their personal experiences is an app and solution that can be trusted even more.</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='heading'>Who or what disappears if your product is successful?</p>
                    <p className='description'>The number of travelers with food allergies is not large enough to put restaurants containing food allergens out of business so that is not a worry. Overall I don't think the restaurant dynamic shifts too much. Restaurant-finding services such as Google Maps or Yelp who are not particularly serving the needs of travelers with food allergies might lose users due to not placing an emphasis on this use-case.</p>
                  </BoxCard>
                  <BoxCard>
                    <p className='heading'> When you picture your user base, who is excluded? If they use your product, what would their experience be like?</p>
                    <p className='description'>Users without food allergies would choose restaurants based on other criteria that become similar to how the current experience of restaurant-finding is. Users would place an emphasis on distance, rating, and may look for a particular dish in the menu. While these are factors for the target user base, the aspect of not being concerned about food allergies creates a very different experience.</p>
                  </BoxCard>
                </Grid>
              </div>
            </CaseStudyStyles>
          </Wrapper>
        </Section>
        <RelatedCarousel doNotInclude="safely" />
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default IndexPage;
