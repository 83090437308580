import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AdditionalSliderStyles from "../../styles/case-study/SliderStyles";

function NextArrow(props) {
  return (
    <div
      style={{ display: "none" }}
    />
  );
}

function PrevArrow(props) {
  return (
    <div
      style={{ display: "none",  }}
    />
  );
}

const settings = {
  arrows: true,
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

class SketchSlider extends React.Component {
  render() {
    return (
      <div>
        <AdditionalSliderStyles>
          <Slider {...settings}>
            <div>
              <img src="/case-study/safely/sketch-to-wireframe-1.png" alt=""></img>
              <p className="caption">Dashboard. After receiving early feedback this screen was re-designed to reflect the most important features immediately.</p>
            </div>
            <div>
              <img src="/case-study/safely/sketch-to-wireframe-2.png" alt=""></img>
              <p className="caption">Search results screen.</p>
            </div>
            <div>
              <img src="/case-study/safely/sketch-to-wireframe-3.png" alt=""></img>
              <p className="caption">Restaurant details screen.</p>
            </div>
            <div>
              <img src="/case-study/safely/sketch-to-wireframe-4.png" alt=""></img>
              <p className="caption">Saved restaurants screen.</p>
            </div>
            <div>
              <img src="/case-study/safely/sketch-to-wireframe-5.png" alt=""></img>
              <p className="caption">Navigation screen.</p>
            </div>
            <div>
              <img src="/case-study/safely/sketch-to-wireframe-6.png" alt=""></img>
              <p className="caption">Translation screen.</p>
            </div>
          </Slider>
        </AdditionalSliderStyles>
      </div>
    );
  }
};

class ColorSlider extends React.Component {
  render() {
    return (
      <div>
        <AdditionalSliderStyles>
          <Slider {...settings}>
            <img src="/case-study/safely/color-1.png" alt=""></img>
            <img src="/case-study/safely/color-2.png" alt=""></img>
            <img src="/case-study/safely/color-3.png" alt=""></img>
            <img src="/case-study/safely/color-4.png" alt=""></img>
        </Slider>
        </AdditionalSliderStyles>
      </div>
    );
  }
};

class A11ySlider extends React.Component {
  render() {
    return (
      <div>
        <AdditionalSliderStyles>
          <Slider {...settings}>
            <img src="/case-study/safely/a11y-1.png" alt=""></img>
            <img src="/case-study/safely/a11y-2.png" alt=""></img>
        </Slider>
        </AdditionalSliderStyles>
      </div>
    );
  }
};

export { 
  SketchSlider,
  ColorSlider,
  A11ySlider,
}